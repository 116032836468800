<template>
  <div>
    <a-card title="操作日志">
      <a-row gutter="16">
        <a-col :span="24" style="width: 256px">
          <a-range-picker @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.creator" placeholder="操作人员" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.module" placeholder="系统模块" allowClear style="width: 100%" @change="search">
            <a-select-option value="仓库管理">仓库管理</a-select-option>
            <a-select-option value="库区管理">库区管理</a-select-option>
            <a-select-option value="库位管理">库位管理</a-select-option>
            <a-select-option value="货主管理">货主管理</a-select-option>
            <a-select-option value="往来单位">往来单位</a-select-option>
            <a-select-option value="订单类型">订单类型</a-select-option>
            <a-select-option value="货品分类">货品分类</a-select-option>
            <a-select-option value="单位管理">单位管理</a-select-option>
            <a-select-option value="货品信息">货品信息</a-select-option>
            <a-select-option value="部门管理">部门管理</a-select-option>
            <a-select-option value="角色管理">角色管理</a-select-option>
            <a-select-option value="员工账号">员工账号</a-select-option>
            <a-select-option value="报工单">报工单</a-select-option>
            <a-select-option value="项目名称">项目名称</a-select-option>
            <a-select-option value="货品批次">货品批次</a-select-option>
            <a-select-option value="计费规则">计费规则</a-select-option>
            <a-select-option value="单据字头">单据字头</a-select-option>
            <a-select-option value="货品子批次">货品子批次</a-select-option>
            <a-select-option value="入库通知单">入库通知单</a-select-option>
            <a-select-option value="出库通知单">出库通知单</a-select-option>
            <a-select-option value="复核记录">复核记录</a-select-option>
            <a-select-option value="拣货记录">拣货记录</a-select-option>
            <a-select-option value="收货记录">收货记录</a-select-option>
            <a-select-option value="上架记录">上架记录</a-select-option>
            <a-select-option value="移库单据">移库单据</a-select-option>
            <a-select-option value="品质调整单据">品质调整单据</a-select-option>
            <a-select-option value="盘点单据">盘点单据</a-select-option>
            <a-select-option value="盘点记录">盘点记录</a-select-option>
            <a-select-option value="入库其他费用记录">入库其他费用记录</a-select-option>
            <a-select-option value="出库其他费用记录">出库其他费用记录</a-select-option>
            <a-select-option value="出库退库记录">出库退库记录</a-select-option>
            <a-select-option value="调拨单据">调拨单据</a-select-option>
            <a-select-option value="货主计费">货主计费</a-select-option>
            <a-select-option value="拣货单据">拣货单据</a-select-option>
            <a-select-option value="损益货品">损益货品</a-select-option>
            <a-select-option value="损益单据">损益单据</a-select-option>
            <a-select-option value="标签管理">标签管理</a-select-option>
            <a-select-option value="LOGO管理">LOGO管理</a-select-option>
            <a-select-option value="容器管理">容器管理</a-select-option>
            <a-select-option value="付款任务">付款任务</a-select-option>
            <a-select-option value="打印管理">打印管理</a-select-option>
            <a-select-option value="系统管理">系统管理</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.action" placeholder="操作类型" allowClear style="width: 100%" @change="search">
            <a-select-option value="新增">新增</a-select-option>
            <a-select-option value="修改">修改</a-select-option>
            <a-select-option value="删除">删除</a-select-option>
            <a-select-option value="导入">导入</a-select-option>
            <a-select-option value="作废">作废</a-select-option>
            <a-select-option value="导入货品数据">导入货品数据</a-select-option>
            <a-select-option value="重置密码">重置密码</a-select-option>
            <a-select-option value="完成">完成</a-select-option>
            <a-select-option value="添加货主">添加货主</a-select-option>
            <a-select-option value="移除货主">移除货主</a-select-option>
            <a-select-option value="快速收货">快速收货</a-select-option>
            <a-select-option value="品质转换">品质转换</a-select-option>
            <a-select-option value="重置密码">重置密码</a-select-option>
            <a-select-option value="批量删除">批量删除</a-select-option>
            <a-select-option value="批量完成">批量完成</a-select-option>
            <a-select-option value="批量作废">批量作废</a-select-option>
            <a-select-option value="清空库存">清空库存</a-select-option>
            <a-select-option value="批量导入入库数据">批量导入入库数据</a-select-option>
            <a-select-option value="设置计费规则">设置计费规则</a-select-option>
            <a-select-option value="快速拣货">快速拣货</a-select-option>
            <a-select-option value="调整库存">调整库存</a-select-option>
            <a-select-option value="保持原系统">保持原系统</a-select-option>
            <a-select-option value="设置">设置</a-select-option>
            <a-select-option value="检查编号">检查编号</a-select-option>
            <a-select-option value="波次合并">波次合并</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.status" placeholder="操作状态" allowClear style="width: 100%" @change="search">
            <a-select-option value="success">成功</a-select-option>
            <a-select-option value="failure">失败</a-select-option>
            <a-select-option value="exception">异常</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" :md="12" :xl="12" style="margin-bottom: 12px; width: 100px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
          </a-button-group>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { userOption } from "@/api/option";
import { operationLogList, operationLogExport } from "@/api/system";
import { exportExcel } from "@/utils/excel";

export default {
  components: {},
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "系统模块",
          dataIndex: "module",
        },
        {
          title: "操作类型",
          dataIndex: "action",
        },
        {
          title: "操作人员",
          dataIndex: "creator",
          customRender: (_, item) => item.creator_item.name,
        },
        {
          title: "主机",
          dataIndex: "remote_ip",
        },
        {
          title: "操作状态",
          dataIndex: "status_display",
        },
        {
          title: "操作时间",
          dataIndex: "create_time",
        },
        {
          title: "消耗时间",
          dataIndex: "request_duration",
          customRender: (value) => `${value * 1000} 毫秒`,
        },
      ],
      searchForm: { search: "", page: 1, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      userItems: [],
    };
  },
  computed: {},
  methods: {
    initData() {
      userOption({ page_size: 99999 }).then((resp) => {
        this.userItems = resp.results;
      });

      this.list();
    },
    list() {
      this.loading = true;
      operationLogList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0];
      let endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    exportExcel() {
      operationLogExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("操作日志"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
